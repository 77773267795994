/* exported shotClock */

class shotClock {
  constructor( selector ) {
    const $shotclock = document.querySelector(selector), shotclock = $shotclock.querySelector('.shotclock');
    
    var interval = null,
        totalTime = shotclock.dataset.seconds,
        timer = this.timer(shotclock, totalTime);
    
    interval = setInterval(() => {
      timer = this.timer(shotclock, timer);
      if ( timer < 0 ) {
        clearInterval(interval);
        shotclock.classList.add('shotclock--expired');
        var $onFinish = document.querySelectorAll('.onShotClockFinish');
        
        $onFinish.forEach(function(el) {
          el.classList.remove('visible');
          el.classList.remove('onShotClockFinish');
          setTimeout(function() {
            el.classList.add('visible');
          }, 500)
        });
      }
    }, 1000);
  }
  timer(shotclock, timer) {
    let minutes = parseInt(timer / 60, 10),
        seconds = parseInt(timer % 60, 10);
        
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      
      shotclock.querySelector('.minutes').innerHTML = minutes;
      shotclock.querySelector('.seconds').innerHTML = seconds;
      
      //shotclock.querySelector('.shotclock__progress').style.width = ( timer / shotclock.dataset.seconds * 100 )+"%";
      
      --timer
      
      return timer;
  }
}