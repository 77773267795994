/* exported pxlPosts */

class pxlPosts {
  constructor( selector, bid, pid, args ) {
    this.$    = document.getElementById(selector);
    this.args = args;
    this.bid  = bid.replace('block_', '');
    this.pid  = pid;
    
    this.el = {
      items: this.$.querySelector('.items'),
      row: this.$.querySelector('.itemgrid'),
      btn: this.$.querySelector('.load-more')
    }
    
    this.listeners();
  }
  listeners() {
    var page = 2;
    
    this.el.btn.addEventListener('click', (e) => {
      e.preventDefault();
      var row = this.el.row.cloneNode();
      
      row.innerHTML = '';
      
      jQuery.get(`${window.location.origin}/wp-json/api/v1/posts/?page=${page}&args=${JSON.stringify(this.args)}&bid=${this.bid}&pid=${this.pid}`, (data) => {
        row.innerHTML = data.posts;
        this.el.items.append(row);
        if ( page === data.pages ) this.el.btn.remove();
        page++;
      });
      
      return false;
    });
  }
}